@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
/*@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');*/
body{font-family:'Poppins', sans-serif;color:#2b2b2b;font-size:16px;padding-right:0 !important;}
*{box-sizing:border-box;}
/*Common*/
ul{padding-left:0;}
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button{-webkit-appearance:none;-moz-appearance:none;appearance:none;margin:0;}
/*Loading start*/

/*Loading start*/
.loading .loading_wrap{display:block;}
.loading_wrap{display:none;position:fixed;z-index:10000;top:0;left:0;height:100%;width:100%;background:rgba(0,0,0,.8) 50% 50% no-repeat;}
.loading_wrap .loading_spinner{animation:animLoading 1s linear infinite;background:transparent;border:4px solid #888;border-bottom-color:#fff;border-radius:50%;height:50px;left:50%;margin:-25px 0 0 -25px;opacity:.7;padding:0;position:absolute;top:50%;width:50px;z-index:10001;}
.loading_wrap{background:rgba(0,0,0,.2) 50% 50% no-repeat;}
.loading_inner{background:#fff;box-shadow:0 0 10px rgba(0, 0, 0, 0.3);padding:10px;width:80px;height:80px;border-radius:8px;position:absolute;z-index:10001;left:50%;top:50%;transform:translate(-50%, -50%);}
.loading_wrap .loading_spinner{animation:animLoading 3s linear infinite;border:4px dotted #000;border-bottom-color:#ee3b33;height:60px;margin:-30px 0 0 -30px;width:60px;opacity:0.8;}
.loading_inner p{font-size:9px;margin:25px 0 0 9px;}
@keyframes animLoading{to{transform:rotate(1turn)}}
/*Loading end*/

/* body.loading .loading_wrap{display:block;}
.loading_wrap{display:none;position:fixed;z-index:10000;top:0;left:0;height:100%;width:100%;background:rgba(0,0,0,.8) 50% 50% no-repeat;}
.loading_wrap .loading_spinner{animation:animLoading 1s linear infinite;background:transparent;border:4px solid #888;border-bottom-color:#fff;border-radius:50%;height:50px;left:50%;margin:-25px 0 0 -25px;opacity:.7;padding:0;position:absolute;top:50%;width:50px;z-index:10001;}
.loading_wrap{background:rgba(255,255,255,.4) 50% 50% no-repeat;}
.loading_inner{background:#fff;box-shadow:0 0 10px rgba(0, 0, 0, 0.3);padding:10px;width:80px;height:80px;border-radius:8px;position:absolute;z-index:10001;left:50%;top:50%;transform:translate(-50%, -50%);}
.loading_wrap .loading_spinner{animation:animLoading 3s linear infinite;border:4px dotted #000;border-bottom-color:#ee3b33;height:60px;margin:-30px 0 0 -30px;width:60px;opacity:0.8;}
.loading_inner p{font-size:9px;margin:25px 0 0 9px;}
@keyframes animLoading{to{transform:rotate(1turn)}} */
/*Loading end*/
/*Header section start*/
.header{box-shadow:0 0 10px rgba(0, 0, 0, 0.15);padding:13px 0;margin-bottom:0;}
.header_additional_info_wrap ul{list-style:none;margin-bottom:0;}
.header_additional_info_wrap ul li{display:inline-block;margin:0 15px;}
.header_additional_info_wrap ul li:last-child{margin-right:0;}
.header_additional_info_wrap ul li a{text-decoration:none;color:#2b2b2b;}
.header_additional_info_wrap ul li .fa{margin-right:7px;}
.header_additional_info_wrap ul li .fa-phone{border:1px solid #2b2b2b;border-radius:30px;width:18px;height:18px;font-size:12px;text-align:center;padding-top:3px;}
.header_additional_info_wrap ul li .fa-phone+span{font-weight:500;}
.header_additional_info_wrap ul li a:hover{color:#ee3b33;}
.header_additional_info_wrap ul li a:hover > .fa-phone{border-color:#ee3b33;}
.header_logo_wrap img {height: 57px;}
/*Header section end*/
/*Footer start*/
.footer{margin-top:80px;}
.footer ul{list-style:none;margin-bottom:0;}
.footer a{text-decoration:none;}
/* .subscribe_wrap{background:url('../img/subscribe_bg.png') no-repeat center;background-size:cover;} */
.subscribe_wrap{padding-top:25px;padding-bottom:25px;}
.subscribe_label p{font-size:20px;line-height:30px;color:#fff;max-width:420px;position:relative;padding-left:82px;margin-bottom:0;}
/* .subscribe_label p::before{content:'';width:60px;height:60px;background:url('../img/ph_icon.png') no-repeat;display:block;position:absolute;top:50%;left:0;transform:translateY(-50%);} */
.subscribe_form_wrap fieldset{border:none;display:flex;align-items:center;position:relative;z-index:1;}
.subscribe_form_wrap fieldset input{height:50px;font-size:16px;}
.subscribe_form_wrap fieldset input[type="number"],.subscribe_form_wrap fieldset input[type="tel"], .subscribe_form_wrap fieldset input[type="email"]{width:calc(100% - 126px);padding:5px 15px;border-radius:4px 0 0 4px;border:1px solid #ced4da;transition:all .3s;border-right:none;}
.subscribe_form_wrap fieldset input.button{width:126px;border:none;outline:0;background:#ee3b33;border-radius:0 4px 4px 0;font-size:16px;color:#fff;font-weight:300;cursor:pointer;transition:all .3s;position:absolute;right:0;top:0;z-index:1;}
.subscribe_form_wrap fieldset input[type="number"]:focus-visible, .subscribe_form_wrap fieldset input[type="tel"]:focus-visible,  .subscribe_form_wrap fieldset input[type="email"]:focus-visible{border-radius:4px 0 0 4px;border:1px solid #ced4da;outline:0;}
.subscribe_form_wrap fieldset input[type="number"]:focus, .subscribe_form_wrap fieldset input[type="tel"]:focus, .subscribe_form_wrap fieldset input[type="email"]:focus{border-radius:4px 0 0 4px;border:1px solid #ee3b33;border-right:none;border-color:#ee3b33;outline:0;box-shadow:none;}
.subscribe_form_wrap form{padding-left:35px;}
.subscribe_form_wrap fieldset input.button:hover{background:#212529;}
.footer_widgets_wrap{background:#212529;padding:50px 0;}
.footer_widgets_inner{display:flex;justify-content:space-between;padding:50px 15px;}
.widgets_content h4{color:#ee3b33;font-size:20px;font-weight:600;margin-bottom:15px;}
.widgets_content ul li{margin-bottom:5px;}
.widgets_content ul li:last-child{margin-bottom:0;}
.widgets_content ul li a{color:#868585;font-size:14px;transition:all .3s;}
.widgets_content ul li a:hover{color:#fff;}
.store_link_wrap{display:block;padding-top:25px;}
.store_link_wrap a{display:inline-block;margin-right:5px;}
.store_link_wrap a:last-child{margin-right:0;}
.footer_copyright_wrap{background:#171a1d;}
.copyright_content{display:flex;justify-content:space-between;align-items:center;padding:20px 0;}
.copyright p{color:#acabab;font-size:14px;margin:0;}
.social_media ul{text-align:center;}
.social_media ul li{display:inline-block;margin:0 10px;}
.social_media ul li a{color:#ee3b33;font-size:20px;transition:all .3s;}
.social_media ul li a:hover{color:#fff;}
.copyright_menu ul li{display:inline-block;margin:0 10px;}
.copyright_menu ul li:last-child{margin-right:0;}
.copyright_menu ul li a{color:#acabab;font-size:14px;transition:all .3s;}
.copyright_menu ul li a:hover{color:#fff;}
/*Footer end*/
/*Steps css start*/
.troubleshooting_qus_block, .service_entry_block{padding-top:45px;}
.troubleshooting_qus_inner{max-width:570px;margin:0 auto;}
.question_block{border:1px solid #d1d1d1;text-align:center;border-radius:10px;}
.question_details, .yes_no_block{padding:20px;}
.prob_title h2{text-align:center;font-size:24px;font-weight:normal;color:#2b2b2b;margin-bottom:20px;}
.question_block h3{font-size:18px;}
.question_block p{font-size:16px;}
.question_block p:last-child{margin-bottom:0;}
.yes_no_block{border-top:1px solid #d1d1d1;}
body .yes_no_btn .btn, body .modal-footer .btn, .problem_blk .continue_with_btn, .error_start_btn{padding:6px 20px;border-radius:5px;font-size:18px;font-weight:500;}

body .vid_play, body .red_btn, body .white_btn{padding:7px 40px;border-radius:50px;font-size:16px;font-weight:500;}
body .modal-footer .btn, body .vid_play, body .red_btn, body .yes_no_btn .btn, body .btn-primary:not(:disabled):not(.disabled).active, body .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle, body .error_start_btn{background-color:#ee3b33;border-color:#ee3b33;}
body .btn-primary.focus, body .btn-primary:focus, body .btn-primary:focus-visible, body .btn-primary:focus:active{box-shadow:0 0 0 .2rem rgba(238, 59, 51,.5);background-color:#ee3b33;border-color:#ee3b33;}
body .btn-primary:focus-visible{outline:none;}
body .yes_no_btn .yes_btn:hover, body .popup_btns .popup_yes_btn:hover, body .yes_no_btn .no_btn, body .popup_btns .popup_no_btn, .vid_play:hover, body .red_btn:hover, body .white_btn, .problem_blk .continue_with_btn, body .error_start_btn:hover{background-color:#fff;color:#ee3b33;border-color:#ee3b33;}
body .yes_no_btn .no_btn:hover, body .popup_btns .popup_no_btn:hover, body .vid_play, body .red_btn, body .white_btn:hover,.problem_blk .continue_with_btn:hover{background-color:#ee3b33;color:#fff;border-color:#ee3b33;}
body .yes_no_btn .yes_btn{margin-right:10px;}
body .modal-footer .btn{width:75px;}
.qus_video_block{text-align:center;margin-top:30px;}
.qus_video_block h3{font-size:18px;}
body .video_popup_wrap{position:fixed;top:50%;left:50%;z-index:1005;width:100%;display:none;max-width:950px;transform:translate(-50%,-50%);}
body .video_popup_inner{position:relative;padding:10px;background:#fff;border-radius:10px;}
body .popup_close_btn, body .modal-header .btn-close{background:#ee3b33;opacity:1;border-radius:50px;width:30px;height:30px;display:flex;justify-content:center;align-items:center;position:absolute;right:10px;top:10px;padding:0;margin:0;color:#fff;z-index:203;cursor:pointer;border:1px solid #ee3b33;}
body .popup_close_btn:hover, body .modal-header .btn-close:hover{background: #fff;color:#ee3b33;}
.popup_overlay{position:fixed;top:0;left:0;width:100%;height:100%;background:rgba(0, 0, 0, .7);z-index:1002;display:none;}
body .btn-close:after {
  content: "×";
  font-size: 30px;
  margin-top: -6px;
}
.video_wrap iframe{width:100%;aspect-ratio:16 / 9;margin-bottom:0;}
.qus_steps_wrap{}
.step_question{display:none;}
.qus_steps_wrap .step_1{display:block;}
/*Steps css end*/
/*Service entry start*/
.service_entry_inner{max-width:700px;margin:0 auto;}
.button_block{justify-content:center;}
.service_call_btn{margin-right:20px;}
/*Service entry end*/
/*Summary start*/
.summary_blk{margin-bottom:20px;border-radius:10px;padding:20px;background:#fff;border:1px solid #d1d1d1;}
.step_title p{font-size:20px;margin-bottom:10px;font-weight:500;line-height:24px;}
.summary_title{width:65%;margin-right:5%;}
.summary_blk .step_title p.ledger_no{font-size:16px;font-weight:normal;width:30%;text-align:right;}
.customer_info p{margin-bottom:0;text-transform:uppercase;font-size:18px;}
.customer_info .cust_name{font-weight:500;}
.cust_problem p{margin-bottom:0;}
.cust_problem p span{font-weight:500;}
.cust_problem{margin-top:10px;}
.popup_msg_wrap .msg p{margin-bottom:0;font-size:20px;line-height:24px;}
.popup_msg_wrap .icon{width:50px;height:50px;display:flex;justify-content:center;align-content:center;background:#fde4e3;border-radius:100px;border:1px solid #ee3b33;margin-right:20px;}
/* .popup_msg_wrap .msg{width:95%;} */
.popup_msg_wrap .icon span{font-size:30px;line-height:40px;}
.popup_msg_wrap .icon .fa::before{display:inline-block;vertical-align:middle;}
body .modal-header{border-bottom:none;}
body .modal-body{padding:10px 25px 25px 25px;}
body .modal-content{border-radius:10px;}
body .modal-header .close{right:15px;top:15px;}
body .modal-footer{justify-content:center;}
.popup_msg_wrap .msg .form-group{margin-top:10px;}


body .serviceEnterpopup .modal-dialog {
  max-width: 550px;
}
.datePicker {
  margin-top: 15px;
}
.datePicker label {
  font-size: 18px;
  display: block;
}
.datePicker input {
  width: 95%;
  height: 38px;
  background: url('../img/calendar_icon.svg') no-repeat right 8px center;
  background-size: 26px;
  padding-left: 10px;
  border: 1px solid #ced4da;
}
.rmdp-container{
  display: block !important;
}

/*Summary end*/
/*Thank you page start*/
.success_circle_wrap{margin-bottom:10px;}
.success_circle{width:50px;height:50px;margin:0 auto;border:2px solid #1cb765;border-radius:50px;display:flex;justify-content:center;align-items:center;}
.success_circle img{width:55%;}
.thankyou_wrap{display:flex;padding:50px 0;max-width:570px;margin:0 auto;}
.thankyou .footer{margin-top:0;}
.thankyou_content .right_content{padding:30px 30px 30px 0;width:55%;}
.check_email p{font-size:16px;margin-bottom:0;}
.left_content .left_img_sec{height:100%;}
.left_img_sec img{max-width:100%;height:100%;}
.left_img_sec::after{content:'';width:100%;height:100%;position:absolute;top:0;right:0;background:rgba(0, 0, 0, 0.5);}
.top_msg_wrap{margin-bottom:10px;}
.thankyou_msg h2{font-size:40px;margin:15px 0 0 0;font-weight:500;}
.thankyou_msg .cust_name{font-size:32px;margin-bottom:0;}
.thankyou_msg{margin-bottom:10px;}
.order_info p{font-size:20px;font-weight:normal;}
.address_block img, .check_email img{width:25px;margin-bottom:10px;}
.top_msg_wrap .alert{padding:7px;margin-bottom:0px;}
.thankyou_content h4{font-size:20px;text-transform:uppercase;}
.thankyou .topSpace{padding-top:0;}
.address_block{padding:15px;}
.order_info p span{font-weight:500;}
.bottom_sec p{font-size:18px;font-weight:500;margin-bottom:10px;margin-top:30px;}
.order_info img{max-width:100%;}
/*Thank you page end*/

.videoModal .modal-dialog {
  max-width: 950px;
}
body .btn:disabled, body .btn.disabled, body fieldset:disabled .btn {
  color: #565f66;
  pointer-events: none;
  background-color: #9fa6ac;
  border-color: #9fa6ac;
  opacity: .8;
} 

/* .problem_blk{background-color:#fff;border-radius:10px;border:1px solid #d1d1d1;padding:20px;margin-bottom:30px;}
.landing_main{margin-top:50px;}
.problem_blk h3{font-size:18px;color:#2b2b2b;}
.problem_blk p{font-size:14px;color:#767676;}
.page_title h1{font-size:24px;line-height:32px;color:#2b2b2b;text-align:center;margin-bottom:20px;} */

.problem_blk{background-color:#fff;border-radius:10px;border:1px solid #d1d1d1;padding:15px;margin-bottom:30px;cursor:pointer;}
.landing_main{padding-top:50px;background-color:#fff; padding-bottom: 50px;}
.problem_blk h3{font-size:18px;color:#006cac;}
.problem_blk p{font-size:14px;color:#767676;margin-bottom:0;}
.page_title h1{font-size:24px;line-height:32px;color:#2b2b2b;text-align:center;margin-bottom:20px;font-weight:normal;}
.page_title h1 span{color:#ee3b33;font-weight:500;}
.problem_blk:hover{background:#f7fafa;}
.problem_listing_wrap a{text-decoration: none;}
.error_wrap{margin-top:50px;}
.errormessage_blk{text-align:center;}
.errormessage_blk h1{font-size:80px;}
.front-footer{margin-top: 0 !important;}
  

@media (max-width:767px){
  .errormessage_blk h1{font-size:60px;}
  .errormessage_blk h3{font-size:1.5rem;}
  .error_wrap, .footer{margin-top:40px;}
  
.landing_main{margin-top:30px;}
.page_title h1{font-size:18px;margin-bottom:10px;line-height:24px;}
.problem_blk h3{font-size:16px;}
.problem_blk .continue_with_btn{font-size:14px;}
.problem_blk{margin-bottom:10px;}
.footer{margin-top:20px;}


  .header_logo_wrap img{height:auto;}
  .header_additional_info_wrap ul li .fa{margin-right:0;}
  .header_additional_info_wrap ul li .fa-phone{width:24px;height:24px;font-size:16px;}
  .header_additional_info_wrap ul li .fa-phone + span{font-size:0;}
  .prob_title h2{font-size:22px;margin-bottom:10px;}
  body .video_popup_wrap{padding:10px;}
  body .video_popup_inner{padding:5px;}
  .summary_blk .step_title{flex-direction:column;}
  .summary_title{width:100%;margin-right:0;}
  .summary_blk .step_title p.ledger_no{width:100%;text-align:left;}
  .order_info p br{display:none;}
  .footer_widgets_wrap .widgets_content >ul{display:none;padding:15px 0px;}
  .footer_widgets_wrap .widgets_content h4{font-size:16px;border-bottom:1px solid #545556;margin:0;padding:10px 0;cursor:pointer;}
  .footer_widgets_wrap .widgets_content h4::after{content:"\f105";color:#ee3b33;margin:0;font-family:'FontAwesome';font-size:16px;display:inline-block;font-weight:normal;float:right;}
  .footer_widgets_wrap .widgets_content.open_footer_wid h4::after{content:"\f107";}
  .store_link_wrap{text-align:center;}
  .footer_widgets_wrap{padding:20px 0;}
  .copyright_content{text-align:center;padding-bottom:20px;}
  .store_link_wrap img{max-width:70%;}
}
@media (max-width:576px){
  .button_block{flex-direction:column;}
  .button_block .btn{max-width:260px;margin:0 auto;}
  .button_block .service_call_btn{margin-bottom:10px;}
  .popup_msg_wrap .icon{display:none;}
  .popup_msg_wrap .msg{width:calc(100% - 20px);}
  .modal-body{padding:10px 20px;}
  .troubleshooting_qus_block, .service_entry_block{padding-top:30px;}
  .step_title p{font-size:18px;}
  .customer_info p{font-size:16px;}
  .thankyou_wrap{padding:30px 0;}
  .thankyou_msg h2{font-size:30px;}
  .success_circle{width:40px;height:40px;}
}