@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
body{margin:0;padding:0;font-family:'Poppins', sans-serif !important;background:#f1f1f1;}
*{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
::-webkit-input-placeholder{color:#c3c3c3;}
a{ text-decoration: none !important;}
.sidebar-wrapper .sidebar-menu{padding:15px 0;}
.sidebar-wrapper .sidebar-menu .header-menu span{font-weight:normal;font-size:18px;padding:0px 20px;display:inline-block;color:#fff;}
.sidebar-wrapper .sidebar-menu ul li a{display:flex;width:100%;text-decoration:none;position:relative;padding:10px 20px;color:#c3c4c7;font-size:18px;align-items:center;transition:all 0.3s ease-in-out;}
.sidebar-wrapper .sidebar-menu ul li a i{margin-right:10px;font-size:12px;width:30px;height:30px;line-height:30px;text-align:center; border-radius:4px;}
.sidebar-wrapper .sidebar-menu ul li.active>a{color:#fff;}
.sidebar-wrapper .sidebar-menu ul li a:hover > i::before{display:inline-block;animation:swing ease-in-out 0.5s 1 alternate;}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after{font-family:"Font Awesome 5 Free";font-weight:900;content:"\f105";display:inline-block;font-style:normal;font-variant:normal;text-rendering:auto;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;text-align:center;background:0;position:absolute;right:15px;top:14px;}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul{padding:5px 0;}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li{padding-left:25px;font-size:13px;}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before{content:"\f111";font-family:"Font Awesome 5 Free";font-weight:400;font-style:normal;display:inline-block;text-align:center;text-decoration:none;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;margin-right:10px;font-size:8px;}
.sidebar-wrapper .sidebar-menu .sidebar-submenu{display:none;}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after{transform:rotate(90deg);right:17px;}
.sidebar-menu ul{margin:0;padding:0;list-style:none;}
.sidebar-is-reduced .sidebar-wrapper .sidebar-menu > ul > li > a{font-size:0;}
.sidebar-is-reduced .sidebar-wrapper .sidebar-menu > ul > li{position:relative;}
.sidebar-is-reduced .sidebar-wrapper .sidebar-menu > ul > li > a + .sidebar-submenu ul li{padding-left:0;}
.sidebar-is-reduced .sidebar-wrapper .sidebar-menu > ul > li> a + .sidebar-submenu{position:absolute;left:100%;background:#494e53;top:0;width:250px;}
.sidebar-is-reduced .sidebar-wrapper .sidebar-menu > ul > li > a + .sidebar-submenu ul li a{padding:5px 20px;}
.sidebar-wrapper .sidebar-menu ul li{padding:0 10px;margin-bottom:5px;}
.sidebar-wrapper .sidebar-menu > ul > li > a:hover, .sidebar-wrapper .sidebar-menu ul li.active > a, .sidebar-wrapper .sidebar-menu ul li.active .sidebar-submenu{background:#494e53;border-radius:5px;color:#fff;}
.sidebar-wrapper .sidebar-menu ul li .sidebar-submenu{margin-top:5px;padding:15px 0;}
.sidebar-is-reduced .sidebar-wrapper .sidebar-menu > ul > li > a{justify-content:center;}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:hover, .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a.active{color:#fff;}
.sidebar_icon{display:inline-block;margin-right:10px;}
.sidebar-is-reduced .sidebar_icon{margin-right:0;}
.dashboard_icon{width:32px;height:20px;background:url('../img/dashboard-icon-grey.png')no-repeat;}
.sidebar-wrapper .sidebar-menu > ul > li.dashboard > a:hover .dashboard_icon{background:url('../img/dashboard-icon-white.png')no-repeat;}
.problem_icon{width:32px;height:30px;background:url('../img/problem-grey.png')no-repeat;}
.sidebar-wrapper .sidebar-menu > ul > li.problem > a:hover .problem_icon, .sidebar-wrapper .sidebar-menu ul li.active a > .problem_icon{background:url('../img/problem-white.png')no-repeat;}
.logout_icon{cursor: pointer; width:32px;height:30px;background:url('../img/logout-grey.png')no-repeat;}
.sidebar-wrapper .sidebar-menu > ul > li.logout > a:hover .logout_icon{cursor: pointer;background:url('../img/logout-white.png')no-repeat;}
.logout_span{cursor: pointer;}
.hamburger-toggle{position:relative;padding:0;background:transparent;border:1px solid transparent;cursor:pointer;order:1;}
.hamburger-toggle [class*='bar-']{display:block;background:#ee3b33;-webkit-transform:rotate(0deg);transform:rotate(0deg);-webkit-transition:.2s ease all;transition:.2s ease all;border-radius:2px;height:2px;width:24px;margin-bottom:4px;}
.hamburger-toggle.is-opened{left:3px;}
.hamburger-toggle.is-opened [class*='bar-']{background:#ee3b33;}
.hamburger-toggle.is-opened .bar-top{-webkit-transform:rotate(45deg);transform:rotate(45deg);-webkit-transform-origin:15% 15%;transform-origin:15% 15%;}
.hamburger-toggle.is-opened .bar-mid{opacity:0;}
.hamburger-toggle.is-opened .bar-bot{-webkit-transform:rotate(45deg);transform:rotate(-45deg);-webkit-transform-origin:15% 95%;  transform-origin: 15% 95%;width:24px;}
.hamburger-toggle:focus{outline-width:0;}
.hamburger-toggle:hover [class*='bar-']{background:#2b2b2b;}
.header-icons-group{display:flex;order:3;margin-left:auto;height:100%;}
.header-icons-group .header-icon:last-child{border-right:0;}
.header-icon{position:relative;display:flex;width:90px;align-items:center;justify-content:center;cursor:pointer;}
.logout_admin .fa{border:1px solid #2b2b2b;border-radius:100px;height:24px;width:24px;display:flex;justify-content:center;align-items:flex-end;  line-height:normal;font-size:18px;margin-right:10px;}
.header-icon:hover, .header-icon:hover .fa{color:#ee3b33;border-color:#ee3b33}
.header{position:relative;top:0;right:0;z-index:10;width:100%;background:#ffffff;-webkit-transition:padding 0.5s ease-in-out;-moz-transition: padding 0.5s ease-in-out;-ms-transition: padding 0.5s ease-in-out;-o-transition: padding 0.5s ease-in-out;transition:padding 0.5s ease-in-out;padding:0 15px;}
.header .logo{width:285px;}
.header .logo .logo_img{text-align:center;}
.header_inner{height:100%;width:100%;display:flex;height:70px;align-items:center;justify-content:stretch;}
.sidebar{width:90px;position:absolute;z-index:10;left:0;top:0;bottom:0;background:#343a40;-webkit-transition:width 0.5s ease-in-out;-moz-transition:width 0.5s ease-in-out;-ms-transition:width 0.5s ease-in-out;-o-transition:width 0.5s ease-in-out;transition:width 0.5s ease-in-out;}
.sidebar .logo{width:100%;background-color:#fff;}
.logo_img img{height:50px;}
.sidebar_content{height:100%;position:relative;}
.sidebar-is-expanded .sidebar{width:300px;}
main.main{width:100%;height:100%;padding:0 0 0 90px;-webkit-transition:padding 0.5s ease-in-out;-moz-transition:padding 0.5s ease-in-out;-ms-transition:padding 0.5s ease-in-out;-o-transition:padding 0.5s ease-in-out;transition:padding 0.5s ease-in-out;background:#f1f1f1;position:relative;min-height:calc(100vh - 70px);}
main.main .content-wrapper{padding:25px 30px;height:100%;}
main.main .content-wrapper .page-content{border-top:1px solid #d0d0d0;padding-top:25px;}
main.main .content-wrapper-with-bg .page-content{background:#fff;border-radius:3px;border:1px solid #d0d0d0;padding:25px;}
main.main .page-title{font-weight:400;margin-top:0;margin-bottom:25px;}
.sidebar-is-expanded main.main{padding-left:300px;}
.breadcrumb{background-color:transparent;padding-left:0;}
.breadcrumb-item a{color:#2b2b2b;}
.breadcrumb-item.active a{color:#ee3b33;}
.home_category_inner{display:flex;align-items:center;}
.home_category_wrap .cat_item{width:260px;background:#fff url('../img/box-bg.png') no-repeat right center;border:1px solid #dee2e6;border-radius:10px;overflow:hidden;min-height:100px;display:flex;align-items:center;margin-right:15px;}
.home_category_wrap .cat_item img{margin-right:20px;}
.home_category_wrap .cat_item:hover{border-color:#ee3b33;}
.home_category_wrap .cat_item > a{display:flex;justify-content: space-between;padding:15px 20px;align-items:center;}
.home_category_wrap .cat_item > a:hover{text-decoration:none;}
.home_category_wrap .cat_item p{margin:0;font-size:24px;font-weight:500;color:#ee3b33;text-transform:uppercase;}
.custom-control-input:checked ~ .custom-control-label::before{color:#fff;border-color:#ee3b33;background-color:#ee3b33;}
.add_new_prob_inner{max-width:650px;}
.add_new_prob_wrap h2, .add_new_question_wrap h2, .all_prob_wrap h2{font-size:30px;font-weight:normal;margin-bottom:20px;}
.add_new_question_inner{max-width:850px;}
#all_prob_table_wrapper{background:#fff;padding:20px;border-radius:5px;border:1px solid #dee2e6;}
.all_prob_inner{max-width:1170px;}
.table_title{margin-bottom:20px;}
.table_title h2{margin-bottom:0;margin-right:15px;}
.react-confirm-alert-button-group button{border:1px solid #fff !important;}
body .btn_add_prob, .react-confirm-alert-button-group button{background-color:#ee3b33 !important;border-color:#ee3b33 !important;padding:5px 15px;}

body .btn_add_prob:hover, body .react-confirm-alert-button-group button:hover{background-color: #fff !important;color:#ee3b33;border-color: #ee3b33 !important;}

.btn_copy_link_prob{ 
  background-color:#fff !important;border-color:#ee3b33 !important;padding:5px 15px;color: #ee3b33 !important; margin-left: 10px;
}

.btn_copy_link_prob:hover{ 
  background-color:#ee3b33 !important;border-color:#ee3b33 !important;color: #fff !important;
}

.d-flex.custom-pagination.justify-content-between {
  margin-bottom: 10px;
}
.schduleDateTable tr th {
  width: 50%; 
  background-color: #f1f1f1;
}
.schduleDateTable tr td { 
  background-color: #fff !important;
}
.schduleDateTable tr td .badge {
  padding: 10px 15px;
} 
.schduledate_icon{width:32px;height:30px;margin-right: 10px; background:url('../img/cal-icon.png')no-repeat;}
.datePicker label {
  font-size: 18px;
}
.datePicker input {
  width: 550px; 
  height: 38px;
  background: #fff url('../img/calendar_icon.svg') no-repeat right 8px center;
  background-size: 26px;
  padding-left: 10px;
  border: 1px solid #ced4da;
} 

main.login_main{
  background: #fff;
  min-height: 100vh;
}
.sidebar-is-expanded main.login_main{
  padding-left: 0 !important;
}
.react-datepicker-wrapper { 
  width: auto !important;
  margin-right: 10px;
}

.dataTables_scrollBody{max-height:600px!important;height:auto!important;}
.all_prob_wrap .table_title{align-items:center;}
.react-confirm-alert-body{padding:30px 20px !important;}

body .react-confirm-alert-button-group{
 justify-content: center !important; 
}

.postionInput tr td input[type="number"]{
  padding: 2px 5px;
  border-radius: 3px;
  margin-right: 5px;
  border-color: #ced4da;
  outline: none;
  max-width:60px
}
.copyLagerN .copyLagernPopup {
  padding: 7px 10px;
  margin-top: auto;
  /* margin-bottom: 18px; */
}
.copyBtnWarp {
  margin-top: 7px !important;
  margin-bottom: 0px;
  margin-right: 0px !important;
  padding-right: 0px !important; 
}
.sendSMS{
  padding-left: 0px !important;
}
body .copyBtnWarp .sendSMSButton {
  background: #fff;
  color: #ee3b33;
}
body .copyBtnWarp .sendSMSButton:hover  {
  background: #ee3b33;
  color: #fff;
}

.copyLagerN .form-group{
  padding-right: 0px;
  margin-bottom : 3px;
  width: 100%;
}
.btn_add_schedul {
  margin-left: 0 !important;
}
.scheduleDateinput{
  position: relative;
}
.btn_add_schedul{
  position: absolute;
  right: 0;
  top: 0;
} 
.scheduleDateinput input{
  width: 570px;
}


/*Common css start*/  
/*Loading start*/
.loading .loading_wrap{display:block;}
.loading_wrap{display:none;position:fixed;z-index:10000;top:0;left:0;height:100%;width:100%;background:rgba(0,0,0,.8) 50% 50% no-repeat;}
.loading_wrap .loading_spinner{animation:animLoading 1s linear infinite;background:transparent;border:4px solid #888;border-bottom-color:#fff;border-radius:50%;height:50px;left:50%;margin:-25px 0 0 -25px;opacity:.7;padding:0;position:absolute;top:50%;width:50px;z-index:10001;}
.loading_wrap{background:rgba(0,0,0,.2) 50% 50% no-repeat;}
.loading_inner{background:#fff;box-shadow:0 0 10px rgba(0, 0, 0, 0.3);padding:10px;width:80px;height:80px;border-radius:8px;position:absolute;z-index:10001;left:50%;top:50%;transform:translate(-50%, -50%);}
.loading_wrap .loading_spinner{animation:animLoading 3s linear infinite;border:4px dotted #000;border-bottom-color:#ee3b33;height:60px;margin:-30px 0 0 -30px;width:60px;opacity:0.8;}
.loading_inner p{font-size:9px;margin:25px 0 0 9px;}
@keyframes animLoading{to{transform:rotate(1turn)}}
/*Loading end*/

.page-item.active .page-link{background-color:#ee3b33;border-color:#ee3b33;}
.page-link{border-color:#dee2e6;}
table.dataTable td, table.dataTable th{padding:10px 5px;border-bottom:1px solid #dee2e6;border-right:0px solid #dee2e6;}
img{max-width:100%;}
table.dataTable th{border-top:1px solid #dee2e6;}
table.dataTable tr th:first-child, table.dataTable tr td:first-child{border-left:1px solid #dee2e6;text-align:center;}
table.dataTable tr th:last-child, table.dataTable tr td:last-child{border-right:1px solid #dee2e6;}
body .btn_red_extsm, body .btn_update_postion{background:#ee3b33;border:1px solid #ee3b33;border-radius:5px;padding:3px 10px;margin-right:5px;font-size:14px;margin-bottom:5px}
body .btn_red_sm{background:#ee3b33;border:1px solid #ee3b33;border-radius:5px;padding:7px 40px;}
body .btn_update_postion:hover, body .btn_red_sm:hover, body .btn_red_extsm:hover{background:#fff;border-color:#ee3b33;color:#ee3b33;}
body .btn_red_lg{background:#ee3b33;color:#fff;border-radius:50px;cursor:pointer;padding:15px 30px;border:1px solid #ee3b33;font-weight:600;
font-size:16px;width:100%;max-width:275px;margin:0 auto;}
body .btn_red_lg:hover{background:#fff;color:#ee3b33;border-color:#ee3b33;}
body .btn.focus, body .btn:focus, body .btn-primary.focus, body .btn-primary:focus{outline:0;box-shadow:0 0 0 .2rem rgba(238, 59, 51, .25);background-color:#ee3b33;border-color:#ee3b33;color:#fff;}
body .btn-primary:not(:disabled):not(.disabled).active, body .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle{
  color:#fff;background-color:#ee3b33;border-color:#ee3b33;}
  body .btn-primary:not(:disabled):not(.disabled).active:focus, body .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(238, 59, 51, .5);}
/*Common css end*/
/*Login page start*/
.slef_service_login{background:#fff;}
.login_wrapper{position:absolute;width:100%;top:50%;left:50%;transform:translate(-50%, -50%);max-width:1200px;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;border-radius:0 60px 0 60px;}
.remember_forgot_password{display:flex;justify-content:space-between;align-items:center;margin:15px auto;}
.login_img_block{width:46%;max-width:550px;position:relative;}
.login_form_block{width:64%;max-width:650px;}
.login_img_block::before{content:'';width:100%;height:100%;position:absolute;left:0;top:0;background:rgba(0, 0, 0, 0.4);border-radius:0 0 0 60px;}
.login_logo{margin-bottom:40px;}
.login_logo img{max-width:280px;}
.login_form_block{display:block;margin:auto;}
.login_form_inner{padding:50px 100px;}
.login_form_block .form-group .fa{position:absolute;top:50%;left:12px;color:#777777;transform:translateY(-50%);}
.login_form_block .form-group{position:relative;}
.login_form_block .form-group input{padding-left:40px;padding-top:11px;padding-bottom:11px;height:auto;}
.login_form_block h4{font-size:30px;color:#ee3b33;font-weight:600;}
.login_form_block p{font-size:18px;color:#2b2b2b;}
.login_form_block p a{font-size:16px;color:#2b2b2b;}
/*Login page end*/
.react-confirm-alert-body h1{font-size:28px;color:#2b2b2b;}
.react-confirm-alert-body p{font-size:16px;color:#2b2b2b;}

.custom-pagination ul li > span{ padding: 7px 10px;}
.table-warp-search-pagination{
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #dee2
}
.popup_msg_wrap .msg, .popup_msg_wrap{
  width: 100% !important;
}
/*Media start*/
@media (min-width:1299px){

}
@media (min-width:992px){
  .login_img_block{display:flex;}
}
@media (max-width:1340px){
  .btn_red_extsm{margin-bottom:5px;}
  .btn_red_extsm:last-child{margin-bottom:0;}
}
@media (max-width:1199px){
  .login_img_block{display:flex;}
  .login_wrapper{width:calc(100% - 30px);}
  div.dataTables_wrapper div.dataTables_filter input{width:75%;}
}
@media (max-width:991px){
  .login_img_block{display:none;}
  .login_form_block{width:100%;}
  #all_prob_table_wrapper{overflow:hidden;}
  #all_prob_table_wrapper .row:nth-child(2) > div{overflow-x:auto;}
  #all_prob_table{min-width:1000px;}
  .all_prob_wrap .btn_add_prob{min-width:200px;}
  div.dataTables_wrapper div.dataTables_filter input{width:70%;}
}
@media (max-width:767px){
  .login_form_inner{padding:40px;}
  .login_form_block h4{font-size:22px;}
  .login_form_block p{font-size:16px;}
  .login_form_block p a, .login_form_block .form-check-label{font-size:14px;}
  .btn_red_lg{padding:10px 30px;}
  .logo_img img{max-width:120px;margin-left:0;}
  .sidebar-is-expanded .sidebar{width:220px;position:fixed;top:70px;left:-300px;transition:left 0.3s ease-in-out;}
  .logout_admin span{display:none;}
  .header-icon{width:auto;}
  .header .logo{width:auto;margin-right:20px;}
  .sidebar-is-expanded main.main{padding-left:0;}
  main.main{padding:0;}
  .sidebar{width:220px;left:0;transition: left 0.3s ease-in-out;}
  .sidebar-is-reduced .sidebar-wrapper .sidebar-menu > ul > li > a, .sidebar-wrapper .sidebar-menu ul li a, .sidebar-wrapper .sidebar-menu .header-menu span{font-size:16px;}
  .sidebar-is-reduced .sidebar-wrapper .sidebar-menu > ul > li > a{justify-content:flex-start;}
  .sidebar-is-reduced .sidebar_icon{margin-right:10px;}
  .sidebar-is-reduced .sidebar-wrapper .sidebar-menu > ul > li > a + .sidebar-submenu{position:relative;left:0;width:200px;}
  .home_category_inner{flex-direction:column;}
  .home_category_wrap .cat_item{width:100%;margin-right:0;margin-bottom:10px;}
  .sidebar-is-reduced .sidebar-wrapper .sidebar-menu > ul > li > a + .sidebar-submenu ul li a{padding:5px 15px;}
  .sidebar-wrapper .sidebar-menu .header-menu span{padding:0px 15px;}
  .home_category_wrap .cat_item p{font-size:20px;}
  .breadcrumb-item a{font-size:16px;}
  .add_new_prob_wrap h2, .add_new_question_wrap h2, .all_prob_wrap h2{font-size:22px;}
  main.main .content-wrapper{padding:15px;}
  .logout_admin .fa{margin-right:0;}
  .hamburger-toggle{top:5px;}
}
@media (max-width:480px){
  .login_logo img{max-width:200px;}
  .login_form_inner{padding:40px 20px;}
  .remember_forgot_password, .all_prob_wrap .table_title{flex-direction:column;}
  .login_logo{margin-bottom:20px;}
  .table_title h2{margin-right:auto;margin-bottom:10px;}
  .all_prob_wrap .btn_add_prob{min-width:auto;margin-right:auto;}
}